<template>
  <div class="home-page">
    <ip-aside />
    <router-view></router-view>
    <create-task-modal v-if="isVisibleCreateTaskModal"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IpAside from "../components/IpAside";
import CreateTaskModal from "../components/Task/CreateTaskModal";

export default {
  name: "home",

  components: {
    IpAside,
    CreateTaskModal
  },

  computed: {
    ...mapState('task', {
      isVisibleCreateTaskModal: state => state.isVisibleCreateTaskModal
    })
  }
}
</script>

<style scoped lang="scss">
  .home{
    &-page{
      background: $aquaHaze;
      height: 100vh;
      display: flex;
      ::v-deep aside{
        flex: none;
      }
    }
  }
</style>