<template>
  <aside>
    <div class="logo">
      <img src="../assets/images/logo.png" alt="logo">
    </div>
    <div class="user" v-click-outside="hide">
      <ip-avatar :user="user || {}" @click.native="openDropdown">
      </ip-avatar>
      <div class="info" @click="openDropdown">
        <strong>{{ firstName }}</strong>
        <strong>{{ lastName }}</strong>
      </div>
      <div
        class="user-dropdown"
        v-if="isOpen">
        <div @click="logOut"
          class="user-dropdown-item">
          Выйти
        </div>
      </div>
    </div>
    <div class="routes">
      <router-link to="/tasks">
        <inline-svg :src="require('../assets/icons/description_24px.svg')" />
        <span>Таски</span>
      </router-link>
      
      <router-link to="/users">
        <inline-svg :src="require('../assets/icons/supervised_user_circle_24px.svg')" />
        <span>Пользователи</span>
      </router-link>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import IpAvatar from "../components/IpAvatar";
import ClickOutside from 'vue-click-outside';

export default {
  name: "ip-aside",

  components: {
    IpAvatar
  },

  directives: {
    ClickOutside
  },

  data() {
    return {
      routes: [
        {
          to: 'tasks',
          icon: 'desrtiption_24px.svg',
          title: 'Таски'
        },
        {
          to: 'users',
          icon: 'supervised_user_circle_24px.svg',
          title: 'Пользователи'
        }
      ],
      isOpen: false
    };
  },

  computed: {
    ...mapState({
      user: state => state.user
    }),

    firstName() {
      return !!this.user && this.user.name && this.user.name.split(' ')[0];
    },

    lastName() {
      return !!this.user && this.user.name && this.user.name.split(' ')[1];
    }
  },

  methods: {
    openDropdown() {
      this.isOpen = true;
    },

    hide() {
      this.isOpen = false;
    },

    logOut() {
      this.$localStorage.remove('rentPulseBackofficeToken');
      this.$router.push({path: '/login'});
    }
  }
}
</script>

<style scoped lang="scss">
aside{
  height: 100%;
  background: $scienceBlue;
  width: 170px;
}
.logo{
  text-align: center;
  padding: 16px 0;
  border-bottom: 1px solid $dodgerBlue;
}
.user{
  padding: 16px;
  display: flex;
  border-bottom: 1px solid $dodgerBlue;
  position: relative;
  ::v-deep .avatar{
    flex: none;
    margin-right: 11px;
  }
  .info{
    width: calc(100% - 43px);
    strong{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
  &-dropdown{
    position: absolute;
    padding: 8px;
    background: $white;
    box-shadow: 0px 10px 50px rgba($black, 0.25);
    border-radius: 4px;
    top: 60px;
    left: 34px;
    min-width: 100px;
    z-index: 10;
    &:after{
      content: '';
      border: 5px solid transparent;
      border-bottom: 5px solid $white;
      position: absolute;
      top: -9px;
      left: 16px;
    }
    &-item{
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: $black;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      color: $red;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
.routes{
  padding: 8px 0;
  a{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: $white;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    position: relative;
    &.router-link-active{
      &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-bottom: 6px solid transparent;
        border-top: 6px solid transparent;
        border-right: 10px solid $aquaHaze;
        clear: both;
      }
    }
    ::v-deep svg{
      margin-right: 6px;
    }
  }
}
</style>