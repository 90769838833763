<template>
  <div
    class="ip-modal-bg"
    :class="{'mobile': isMobile}">
    <div
      class="ip-modal"
      :class="[size]">
      <div
        v-if="!hideHeader"
        class="ip-modal-header">
        <div class="flex-container">
          <span class="title">{{ headerText }}</span>
          <div
            class="close-btn"
            @click="$emit('close')">
            <inline-svg
              :src="require('../assets/icons/close_24px.svg')"
            ></inline-svg>
          </div>
        </div>
        <slot name="description"></slot>
      </div>
      <div
        class="content"
        :class="{ 'with-border': contentBorder }">
        <slot name="content">
        </slot>
      </div>
      <div
        v-if="!hideFooter"
        class="buttons"
        :class="{ 'with-border': contentBorder }">
        <slot name="buttons">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @property {string} size
     */
    size: {
      type: String,
      default: 'medium'
    },
    /**
     * @property {string} headerText
     */
    headerText: {
      type: String,
      default: ''
    },
    /**
     * @property {boolean} isMobile
     */
    isMobile: {
      type: Boolean,
      default: false
    },
    /**
     * @property {boolean} contentBorder
     */
    contentBorder: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.ip-modal {
  background: $white;
  box-shadow: 0 4px 20px -10px rgba($black, 0.2), 0 25px 50px -20px rgba($black, 0.5);
  border-radius: 4px;
  padding: 40px 0 24px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  box-sizing: border-box;
  &.medium {
    max-width: 570px;
  }
  &.large {
    max-width: 770px;
  }

  &-bg {
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($godGray, .7);
    &.mobile{
      align-items: flex-start;
      padding: 24px 0;
      overflow: auto;
      .ip-modal{
        max-height: inherit;
        .content{
          overflow: visible;
        }
      }
    }
  }

  &-header {
    margin-bottom: 24px;
    flex: none;
    padding: 0 40px;
    .flex-container{
      align-items: flex-start;
      justify-content: space-between;
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      padding-right: 48px;
    }

    .close-btn {
      ::v-deep svg {
        path {
          fill: $alto !important;
        }
      }
      &:hover {
        cursor: pointer;
        ::v-deep svg {
          path {
            fill: $scienceBlue !important;
          }
        }
      }
    }
  }

  .content {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 40px;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: $gray;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $blue;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
    &.with-border{
      border-top: 1px solid $athensGray;
    }
  }

  .buttons{
    padding: 24px 40px 0 40px;
    &.with-border{
      border-top: 1px solid $athensGray;
    }
  }

  @media screen and (max-width: 1000px) {
    padding: 24px 15px 16px 15px;
    max-width: 100% !important;
    &-header{
      .title{
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}
</style>
