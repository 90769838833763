<template>
  <ip-modal
    header-text="Создать таску"
    @close="hideCreateTaskModal"
    class="create-task-modal">
    <template v-slot:content v-if="isLoading">
      <div class="loader-container">
        <ip-loader />
      </div>
    </template>
    <template v-slot:content v-else>
      <div class="create-task__elem">
        <ip-text-input
          name="phone"
          id="phone"
          v-model="phone"
          placeholder="+380"
          label="Клиент"
          v-validate="{required: true}"
          :invalid="errors.has('phone') || isEmptyResult" />
          <div
            v-if="isEmptyResult"
            class="error-message">
            Номера телефона нет в системе
          </div>
      </div>
      <div class="create-task__elem">
        <ip-select
          :options="themes"
          label="Тема"
          placeholder="Не выбрано"
          @onSelect="value => theme = value"
          :selected-item="theme"
          name="theme"
          v-model="theme"
          v-validate="{required: true}"
          :invalid="errors.has('theme')"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <ip-button
        theme="rounded"
        color="blue"
        @click="createTask"
        :disbled="isLoading">
        Создать
      </ip-button>
    </template>
  </ip-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IpLoader from "../../components/IpLoader";
import IpModal from "../../components/IpModal";
import IpTextInput from "../../components/IpTextInput";
import IpSelect from "../../components/IpSelect";
import IpButton from "../../components/IpButton";
import apiRoutes from '../../consts/apiRoutes';

export default {

  components: {
    IpModal,
    IpTextInput,
    IpSelect,
    IpButton,
    IpLoader
  },

  data() {
    return {
      phone: '',
      theme: '',
      isEmptyResult: false,
      themes: [
        {
          value: 'else',
          text: 'Другое'
        },
        {
          value: 'consultation',
          text: 'Консультация'
        },
        {
          value: 'law_and_security',
          text: 'Юридические или вопросы безопасности'
        }
      ],
      isLoading: false
    };
  },

  mounted() {
    if(this.predefinedPhone) {
      this.$nextTick();
      this.phone = this.predefinedPhone;
    }
  },

  computed: {
    ...mapState('task', {
      predefinedPhone: state => state.predefinedPhone
    })
  },

  methods: {
    ...mapMutations('task', [
      'hideCreateTaskModal',
      'setTask'
    ]),

    async createTask() {
      this.isEmptyResult = false;
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      try {
        const result = await this.axios.post(apiRoutes.clients, { search: this.phone });
        if (!result.data.clients.length) {
          this.isEmptyResult = true;
          this.isLoading = false;
          return;
        }

        const task = {
          user: result.data.clients[0],
          theme: this.theme,
          phone: this.phone.trim()
        };
        this.setTask(task);
        this.hideCreateTaskModal();
        this.isLoading = false;
        this.theme = '';
        this.phone = '';
        this.$router.push({ name: 'task-create' })
      } catch (error) {
        this.isLoading = false;
        console.warn(error);
      }
    }
  }

}
</script>

<style scoped lang="scss">

.create-task{
  &-modal{
    ::v-deep .content{
      overflow: visible;
    }
  }
  &__elem{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
</style>